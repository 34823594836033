import Service from "./Service";
const baseurl = '/api/historia-antecedente';

export default {
    index(filter = {}) {

        return Service.get(`${baseurl}/`, {
            params: {
                ...filter
            }
        });
    },
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
    show(id) {
        return Service.get(`${baseurl}/show/${id}`);
    },
    update(obj) {
        return Service.put(`${baseurl}/update`, obj);
    },
    delete(id) {
        return Service.delete(`${baseurl}/delete/${id}`);
    },
    estado(id) {
        return Service.put(`${baseurl}/update-estado/${id}`);
    },

}